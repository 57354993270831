<template>
    <div class="layout-service">

        <!--s: Service Menu-->
        <ServiceMenu v-if="!isPhone()"/>
        <!--e: Service Menu-->

        <div class="layout-service-content">
            <div class="service-box">
               <h1 class="title" v-if="!isPhone()"></h1>
               <h1 class="title" v-if="isPhone()">
               {{ $t('footer.termsConditions') }}
               <a href="javascript:" class="close" @click="onClose"></a>
               </h1>
                <div class="terms-box">
                    <div class="terms-text">
                        {{ $t('footer.terms1') }}<br/><br/>
                        {{ $t('footer.terms2') }}<br/><br/>
                        {{ $t('footer.terms3') }}<br/><br/>
                        {{ $t('footer.terms4') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms5') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms6') }}<br/><br/>
                        {{ $t('footer.terms7') }}<br/><br/>
                        {{ $t('footer.terms8') }}<br/><br/>
                        {{ $t('footer.terms9') }}<br/><br/>
                        {{ $t('footer.terms10') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms11') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms12') }}<br/><br/>
                        {{ $t('footer.terms13') }}<br/><br/>
                        {{ $t('footer.terms14') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms15') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms16') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms17') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms18') }}<br/><br/>
                        {{ $t('footer.terms19') }}<br/><br/>
                        {{ $t('footer.terms20') }}<br/>
                        {{ $t('footer.terms21') }}<br/>
                        {{ $t('footer.terms22') }}<br/>
                        {{ $t('footer.terms23') }}<br/>
                        {{ $t('footer.terms24') }}<br/>
                        {{ $t('footer.terms25') }}<br/><br/>
                        {{ $t('footer.terms26') }}<br/><br/>
                        {{ $t('footer.terms27') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms28') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms29') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms30') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms31') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms32') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms33') }}<br/>
                        {{ $t('footer.terms34') }}<br/>
                        {{ $t('footer.terms35') }}<br/>
                        {{ $t('footer.terms36') }}<br/><br/>
                        {{ $t('footer.terms37') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms38') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms39') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms40') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms41') }}<br/><br/>
                        {{ $t('footer.terms42') }}<br/><br/>
                        {{ $t('footer.terms43') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms44') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms45') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms46') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms47') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms48') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms49') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms50') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms51') }}
                    </div>
                    <span class="terms-title">{{ $t('footer.terms52') }}</span>
                    <div class="terms-text">
                        {{ $t('footer.terms53') }}<br/><br/>
                        {{ $t('footer.terms54') }}<br/><br/>
                        {{ $t('footer.terms55') }}<br/><br/>
                        {{ $t('footer.terms56') }}<br/><br/>
                        {{ $t('footer.terms57') }}<br/><br/>
                        {{ $t('footer.terms58') }}<br/><br/>
                        {{ $t('footer.terms59') }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import TermsConditions from './termsConditions'
export default TermsConditions
</script>
<style lang="less">
@import "termsConditions";
</style>
